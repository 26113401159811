export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/404": [5],
		"/blog": [7],
		"/changelog": [8],
		"/features": [9],
		"/free-tools": [10],
		"/free-tools/codeblock-highlight": [11,[2,3]],
		"/free-tools/codeblock-highlight/[language]": [12,[2,3]],
		"/free-tools/favicon-generator/from-emoji": [13,[2]],
		"/free-tools/qr-code-generator": [14,[2]],
		"/[...slug]": [6]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';